import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Link } from "gatsby"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import LinkWithArrow from "components/link-with-arrow"
import Hero from "./hero"
import Container from "components/container"

const StyledVideoList = styled.section`
  margin: 60px auto;
  display: grid;
  grid-auto-flow: column;
  max-width: 1200px;

  ${Container} {
  	max-width: 1200px;
    width: 100%!important;
    padding: 0px;
    margin: 0px auto;
  }
`

const StyledSingleVideo = styled.div`
		display: none;
		opacity: 0;
		float: initial;
		width: 95%;
		height: auto;
		text-align: center;
		align-item: center;
		justify-item: center;
		margin: 0 auto 30px; 
		padding:0px;
		position: relative;
		border: 1px ${colors.gray} solid;
		
		${breakpoint.small`
			float: left!important;
			width: 98%;
			margin: 0 0px 20px 20px; 
			width: 47%;
		`}

		${breakpoint.extraLarge`
			width: 100%;
			margin: 0 0px 20px 20px; 
			width: 31%;
		`}
		
		&.visible {
			display: block;
			opacity: 1;
			transition: opacity ease-in 1s;
		  }	  
		  
  	&:hover {
		cursor: pointer;
  	}

	.gatsby-image-wrapper {
		width: 100%!important;
		height: 100%!important;
		margin:0px!important;
		padding: 0px !important;			
	}
	
	
	h2 {
		font-weight: 600;
		font-size: 24px;
		margin:0.5rem 0.5rem 0;
		padding: 0 10px;
	}

    p {
		font-size: 17px;
		line-height: 1.4em;
		margin: 10px;
		
		${breakpoint.small`
			padding: 0px 10px;
			font-size: 15px;
			margin: 5px;
		`}

		${breakpoint.large`
			font-size: 16px;
			line-height: 1.4em;
		`}
    }

	.pubdate {
		margin-top: 10px;
		font-size: 13px;
	}

	.underneath {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		height: 250px;

		&:hover {
  			outline: 1px solid ${colors.blackMedi};
		}

	}

	.thumbnail.gatsby-image-wrapper:hover {
		opacity: 0.6;
	}

	.thumbnail:hover ~ .underneath{
  		outline: 1px solid ${colors.blackMedi};
	}
	
	.link {
		margin-top: 5px;
		align-self: center;
	}

	.miscData {
		display: none;
	}

`

const SingleVideo = props => (
	<StyledSingleVideo
		data-filter={props.category}
		data-filter-type="videos"
		itemscope itemprop="VideoObject"
		itemScope itemType="http://schema.org/VideoObject"
	>
		<Link to={'/resources/videos/' + props.slug + '/'} style={{ display: "inline-block" }}>
			{props.image ? (
				<GatsbyImage
					image={getImage(props.image)}
					width={600}
					alt={props.title}
					className="thumbnail"
				/>
			) : null}
		</Link>
		<div className="underneath">
			<h2 itemProp="name">{props.title}</h2>
			<p itemProp="description">{props.description}
				<br /><span className="pubdate">{props.publishDate}</span>
			</p>
			<LinkWithArrow
				to={'/resources/videos/' + props.slug + '/'}
				className="link"
				text="Play Video"
				color={colors.orange}
				data-aos="fade-up"
			/>
		</div>
		<div className="miscData">
			<meta itemProp="uploadDate" content={props.date} />
			<meta itemProp="contentUrl" content={process.env.GATSBY_VISBY_URL + '/resources/videos/' + props.slug + '/'} />
			{props.publicURL ? (
				<div>
					<meta itemProp="thumbnailUrl" content={process.env.GATSBY_VISBY_URL + props.publicURL} />
					<meta itemProp="og:image" content={process.env.GATSBY_VISBY_URL + props.publicURL} />
				</div>
			) :
				<div>
					<meta itemProp="thumbnailUrl" content={process.env.GATSBY_VISBY_DEFAULT_IMAGE} />
					<meta itemProp="og:image" content={process.env.GATSBY_VISBY_DEFAULT_IMAGE} />
				</div>
			}
		</div>
		<div itemProp="author" itemScope itemType="https://schema.org/Organization">
			<meta itemProp="name" content="Visby Medical" />
			<meta itemProp="url" content={process.env.GATSBY_VISBY_URL} />
		</div>
	</StyledSingleVideo>
)

const VideoList = props => {
	const data = useStaticQuery(graphql`
    query {
      allWpVideo (filter: {videos: {category: {ne: "appearances"}}}, sort: {order: DESC, fields: date} ) {
        nodes {
			id
			title
			slug
			date
			videos {
			  description
			  url
			  category
			  publishDate
			  fullDescription
			  videoImage {
				  localFile {
				publicURL
				  childImageSharp {
					  gatsbyImageData
				  }
				}
			  }
			}
		  }
		}
	  }
	`)
	return (
		<StyledVideoList data-aos="fade-up">
			<Container>
				{data.allWpVideo.nodes.map((video) => (
					<SingleVideo
						key={video.id}
						title={video.title}
						date={video.date}
						description={video.videos.description}
						category={video.videos.category}
						url={video.videos.url}
						image={video.videos.videoImage.localFile}
						publicURL={video.videos.videoImage.localFile.publicURL}
						fullDescription={video.videos.fullDescription}
						slug={video.slug}
						toggleFunction={props.toggleFunction}
						publishDate={video.videos.publishDate}
						className="visible"
					/>
				))}
			</Container>
		</StyledVideoList>
	)
}

class Videos extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filter: "all",
			videofilter: "video"
		}
	}

	componentDidMount() {
		var filtby = this.state.filter;
		if (this.props.filterby)
			filtby = this.props.filterby;

		this.handleFilterChange("videos", filtby, null);
	}

	handleFilterChange = (type, value, event) => {
		const inactiveFilters = document.querySelectorAll("[data-filter]")
		inactiveFilters.forEach(filter => {
			filter.classList.remove("active")
		})

		const activeFilter = document.querySelector(
			"[data-filter][data-target='" + value + "']"
		)
		activeFilter.classList.add("active")

		if (value === "all") {
			const elementsToFilter = document.querySelectorAll(
				"[data-filter-type='" + type + "']"
			)

			elementsToFilter.forEach(post => {
				post.classList.add("visible")
			})
		} else {
			const elementsToFilter = document.querySelectorAll(
				"[data-filter-type='" + type + "'][data-filter='" + value + "']"
			)
			const previousElements = document.querySelectorAll(
				"[data-filter-type='" + type + "']"
			)

			previousElements.forEach(post => {
				post.classList.remove("visible")
			})

			elementsToFilter.forEach(post => {
				post.classList.add("visible")
			})
		}
	}

	handleToggleChange = (type, value, event) => {

		const activeFilters = document.querySelectorAll(
			"[data-filter][data-target='" + value + "']"
		)
		activeFilters.forEach(filter => {
			filter.classList.toggle("active")
		})

	}



	render = () => (
		<React.Fragment>
			<Hero filterFunction={this.handleFilterChange} />
			<VideoList
				toggleFunction={this.handleToggleChange}
			/>
		</React.Fragment>
	)
}

export default Videos
